/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages  







                var nScrollStart = 0;
                $(document).scroll(function() {
                    nScrollStart = $(this).scrollTop();

                    if (nScrollStart >= 1) {
                        $(".main-header").addClass("fixed");
                    } else {
                        $(".main-header").removeClass("fixed");
                    }
                });


                var stickyOffset = $(".single-form-cart").offset().top;
                var SidebarScrollStart = 0;
                $(document).scroll(function() {
                    SidebarScrollStart = $(this).scrollTop();

                    if (SidebarScrollStart >= stickyOffset) {   
                        $(".single-form-cart").addClass("fixed");
                    } else {
                        $(".single-form-cart").removeClass("fixed");
                    }
                    
                });






                // Also attach it to your final add to cart button
                $(".final_add_to_cart_button").on("click", function(e){
                    //alert('click');
                    $(".qib-button-wrapper").addClass("d-flex");

                    e.preventDefault();
                    var form = $(this).closest("form");
                    
                    form.submit(); // Add to cart

                });


                //Start the EqualHeight Function 
                function updateViewportDimensions() {
                    var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
                    return { width:x,height:y };
                }
                // setting the viewport width
                var viewport = updateViewportDimensions();

                var waitForFinalEvent = (function () {
                    var timers = {};
                    return function (callback, ms, uniqueId) {
                        if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
                        if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
                        timers[uniqueId] = setTimeout(callback, ms);
                    };
                })();
                
                // how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
                var timeToWaitForLast = 100;
                
                /*
                 * Set the same height for elements
                */
                $.fn.equalHeights = function(per_row) {
                    var currentTallest  = 0,
                        $this           = $(this);

                    function mapCallback(item) {
                        currentTallest  = 0;
                        $(this).css({'min-height': '1px'});
                        if ($(this).outerHeight() > currentTallest) {
                            currentTallest = $(this).outerHeight();
                        } 
                        return {
                            currentTallest : item[0],
                        };
                    } 

                    if (per_row) {
                        for (var i=0; i<$this.length; i=i+per_row) {
                            currentTallest  = 0;
                            $this.slice(i, i+per_row).each(mapCallback);
                            $this.slice(i, i+per_row).css({'min-height': currentTallest});
                        }
                    } else {
                        $this.each(function(){
                            $(this).css({'min-height': '1px'});
                            if ($(this).outerHeight() > currentTallest) {
                                currentTallest = $(this).outerHeight();
                            }
                        });
                    
                        $this.css({'min-height': currentTallest});
                    }
                    return this;
                };

                /*
                 * Put all your regular jQuery in here.
                */
                $(document).ready(function($) {
                   // Make elements the same min-height
                   $('#section1 .item').equalHeights();
                   $('.product-details').equalHeights();
                }); /* end of as page load scripts */
                
                $(window).load(function() {
                    // Make elements the same min-height 
                    $('#section1 .item').equalHeights();
                    $('.product-details').equalHeights();
                });
                
                // Fire needed update on window resize
                $(window).resize(function () {
                    waitForFinalEvent( function() {
                        $('#section1 .item').equalHeights();  
                        $('.product-details').equalHeights();  
                    }, timeToWaitForLast, "homepage_update_fn");
                });

                
                $('#menu-toggle').click(function () {
                    $(this).toggleClass('open');
                });

                $('.pushy ul li a').click(function () {
                    $(this).toggleClass('open');
                    $(this).next().toggleClass('open');
                });


                



                // Owl Carousel for Single Product
                $('.owl-carousel-single-product').owlCarousel({
                    loop:true,
                    margin:10,
                    dots:false,
                    nav:false,
                    lazyLoad:true,
                    autoplay: true,
                    autoPlaySpeed: 5000,
                    autoPlayTimeout: 5000,
                    autoplayHoverPause: true,
                    responsive:{
                        0:{
                            items:1
                        },
                        600:{
                            items:3
                        },
                        1000:{
                            items:3
                        }
                    }
                });


















            }, 
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },  
        // Contact us page, note the change from about-us to contact_us.
        'contact': {
            init: function() {
                
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
